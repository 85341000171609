import React from "react";

import NavBar from "../../Components/NavBar/NavBar";
import CardCollection from "../../Components/CardCollection/CardCollection";



const HomePage = () =>  {

    return(
        <>
            <NavBar/>
            <CardCollection/>
        </>
    )
}

export default HomePage